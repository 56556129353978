<template>
  <v-app>
    <v-container>
      <v-card
        elevation="0"
        outlined
        color="primary"
        class="rounded-lg mt-10 mb-5 pb-2 pt-1"
      >
        <v-row class="mx-auto">
          <v-col cols="7">
            <!-- Week indicator -->
            <p class="mb-0 pa-0 p-week">
              <b> Results week {{ week }} </b> ({{ year }})
            </p>

            <!-- previous and next week -->
            <v-btn
              @click="week_back"
              elevation="3"
              x-small
              class="mr-1"
              color="info"
            >
              <v-icon>mdi-step-backward</v-icon>
            </v-btn>

            <v-icon>mdi-history</v-icon>
            <v-btn
              @click="week_forward"
              elevation="3"
              x-small
              class="ml-1"
              color="info"
              :disabled="latestWeek"
            >
              <v-icon>mdi-step-forward</v-icon>
            </v-btn>
          </v-col>

          <!-- save button visible on scroll -->
          <v-expand-transition>
            <div
              class="fixed background-dashboard mr-5"
              v-scroll="onScroll"
              v-show="isShown"
            >
              <p class="ml-3 mb-0 mr-0">
                <i>save</i>
              </p>
              <v-btn
                @click="save_selected"
                elevation="3"
                class="ml-3 mb-3 mt-0 mr-0"
                small
                color="info"
              >
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </div>
          </v-expand-transition>

          <!-- save and export -->
          <v-col cols="3" class="text-right mr-0">
            <p class="mb-0 text-right">
              <i>export</i>
            </p>
            <v-btn
              @click="download_nbib"
              elevation="3"
              class="mr-0"
              small
              color="secondary"
            >
              <v-icon color="info">mdi-file-export</v-icon>
            </v-btn>
            <p class="mb-0 text-right">
              <i>save</i>
            </p>
            <v-btn
              @click="save_selected"
              elevation="3"
              class="mr-0"
              small
              color="info"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="2">
            <p class="text-right">Last export:</p>
            <p v-if="currentWeekLastUpdate" class="text-right mt-0">
              {{ currentWeekLastUpdate | formatDate }}
            </p>
            <p v-else class="text-right mt-0">no export yet</p>
          </v-col>
        </v-row>
      </v-card>

      <!-- article counter -->
      <v-row class="mt-0">
        <v-col align-self="end" class="">
          <v-card
            elevation="1"
            outlined
            color="primary"
            class="rounded-lg"
            style="display: inline-block"
          >
            <p class="mb-0 pa-2">
              <b>{{ selectedArticles }}</b> of
              <b>{{ totalArticles }}</b> articles selected for export
            </p>
          </v-card>
        </v-col>

        <!-- select all -->
        <v-col class="checkbox-padding-left">
          <p class="text-right ma-0 pa-0">select all</p>
          <v-container fluid class="flex-end pt-0">
            <v-checkbox
              class="mt-0 pt-0"
              v-model="selectAll"
              color="info"
              @change="select_All()"
            >
            </v-checkbox>
          </v-container>
        </v-col>
      </v-row>

      <!-- loading  -->
      <div v-if="loadingDocs">
        <center>
          <h4>loading...</h4>
          <v-progress-circular
            :size="50"
            :width="4"
            indeterminate
            color="info"
          ></v-progress-circular>
        </center>
      </div>
      <div v-else-if="savingDocs">
        <center>
          <h4>saving...</h4>
          <v-progress-circular
            :size="50"
            :width="4"
            indeterminate
            color="info"
          ></v-progress-circular>
        </center>
      </div>
      <div v-else>
        <!-- begin for loop -->
        <div v-for="doc in docs" :key="doc.pmid">
          <!-- <div v-if="doc.score > 0.8"> -->
          <v-row>
            <v-col cols="10">
              <Article
                :abstract="doc.abstract"
                :title="doc.title"
                :authors="doc.authors"
                :pmid="doc.pmid"
                :data="doc.date"
                :journal="doc.jabbrv"
                :score="doc.score"
              />
            </v-col>
            <v-col class="checkbox-padding-left">
              <v-container fluid class="flex-end">
                <v-checkbox color="info" v-model="doc.selected" class="">
                </v-checkbox>
              </v-container>
            </v-col>
          </v-row>
          <hr />
          <!-- </div> -->
        </div>
        <!-- end for loop -->
      </div>

      <v-row class="mx-auto mt-5 mb-5">
        <v-col cols="11"></v-col>
        <v-col cols="1" class="text-center background-dashboard pt-0">
          <p class="mb-0 mt-1">
            <i>export</i>
          </p>
          <v-btn
            @click="download_nbib"
            elevation="3"
            class="mr-0"
            small
            color="secondary"
          >
            <v-icon color="info">mdi-file-export</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import Article from "../components/Article.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Article,
  },
  name: "Dashboard",
  data() {
    return {
      year: 2021,
      week: 51,
      currentWeek: 51,
      selectAll: false,
      isShown: false,
    };
  },
  computed: {
    ...mapState(["docs", "loadingDocs", "savingDocs", "currentWeekLastUpdate"]),
    totalArticles: function () {
      return this.docs.length;
    },
    selectedArticles: function () {
      const arr = this.docs.map((doc) => doc.selected);
      const count = arr.filter(Boolean).length;
      return count;
    },
    latestWeek: function () {
      const shown = this.week;
      const current = this.currentWeek;
      if (shown === current) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions(["loadDocs", "saveSelected", "setLastUpdate"]),
    loadPage() {
      this.loadDocs({
        year: this.year,
        week: this.week,
      });
    },
    select_All() {
      if (this.selectAll == false) {
        this.docs.map((doc) => {
          doc.selected = false;
          return doc;
        });
      } else {
        this.docs.map((doc) => {
          doc.selected = true;
          return doc;
        });
      }
    },
    download_nbib() {
      this.setLastUpdate({
        year: Number(this.year),
        week: Number(this.week),
      });

      const nbib = this.docs.map((doc) => {
        if (doc.selected && doc.nbib) {
          return doc.nbib;
        }
      });

      const blob = new Blob([nbib.join("\n")], {
        type: "text/plain",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      const fileName =
        "export_year_" + this.year + "_week_" + this.week + ".txt";
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    save_selected() {
      this.saveSelected();
    },
    week_back() {
      if (this.week > 1) {
        this.week--;
      } else {
        this.year--;
        this.week = 52;
      }
      this.loadPage();
    },
    week_forward() {
      if (this.week < 52) {
        this.week++;
      } else {
        this.year++;
        this.week = 1;
      }
      this.loadPage();
    },
    getWeek(date) {
      if (!(date instanceof Date)) date = new Date();
      var nDay = (date.getDay() + 6) % 7;
      // ISO 8601 states that week 1 is the week with the first Thursday of that year
      date.setDate(date.getDate() - nDay + 3);
      var n1stThursday = date.valueOf();
      date.setMonth(0, 1);
      if (date.getDay() !== 4) {
        date.setMonth(0, 1 + ((4 - date.getDay() + 7) % 7));
      }
      return 1 + Math.ceil((n1stThursday - date) / 604800000);
    },
    onScroll() {
      if (window.scrollY <= 0) {
        this.isShown = false;
      } else {
        this.isShown = true;
      }
    },
  },
  mounted() {
    const currentdate = new Date();
    this.week = this.getWeek(currentdate);
    this.year = currentdate.getFullYear();
    this.loadPage();
    const currentdateCheck = new Date();
    this.currentWeek = this.getWeek(currentdateCheck);
  },
};
</script>
